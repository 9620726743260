<template>
  <div class="root">
    <div class="text-center comment">
      <h1 class="main_title">{{ lang.intro_title }}</h1>
      <h3 v-html="lang.intro_subtitle" class="col-md-6 m-auto my-3"></h3>
      <div class="nuage_container">
        <img src="../assets/nuage.svg" class="nuage" />
        <img src="../assets/nuage.svg" class="nuage nuage2" />
      </div>
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="intro_etape">{{ lang.intro_etape }}</div>

          <div class="encart-etape">
            <div class="num">1</div>
            <div class="txt">{{ lang.intro_etape1 }}</div>
          </div>
          <div class="encart-etape">
            <div class="num">2</div>
            <div class="txt">{{ lang.intro_etape2 }}</div>
          </div>
          <div class="encart-etape">
            <div class="num">3</div>
            <div class="txt">{{ lang.intro_etape3 }}</div>
          </div>
        </div>
      </div>

      <div class="bottom-bar">
        <div class="container">
          <div class="row">
            <router-link to="/critere1" class="btn btn-primary d-block">{{
              lang.valid_intro
            }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.comment {
  background: url("../assets/illustration_bas.svg") bottom center no-repeat;
  //height: 94%;
  flex: 1;
}
.nuage_container {
  overflow: hidden;
  margin: -30px -15px;
  position: absolute;
  width: 100%;
}
.nuage {
  position: relative;
  left: 100%;
  animation: floatNuage 30s infinite linear;
}
.nuage2 {
  margin-top: 20px;
  animation: floatNuage 35s infinite linear;
  opacity: 0.4;
}
@keyframes floatNuage {
  to {
    left: -100%;
  }
}
.intro_etape {
  padding-top: 30px;
  font-weight: bold;
}
.encart-etape {
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(5px);
  --webkit-backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.9);
  margin: 10px 0;
  display: flex;
  align-items: center;
  .num {
    flex-shrink: 0;
    border-radius: 50%;
    border: 2px solid #e75c21;
    background: #fff;
    color: #e75c21;
    text-align: center;
    width: 30px !important;
    height: 30px;
    line-height: 26px;
    margin: 15px;
    display: block;
    font-weight: 600;
  }
  .txt {
    font-size: 0.9rem;
    text-align: left;
    margin: 10px;
  }
}
</style>

<script>
export default {
  name: "intro",
  computed: {
    lang() {
      let langIndex = this.$store.state.lang;
      return this.$store.state.languages[langIndex];
    },
  },
  methods: {
    initialisation() {
      this.$store.commit("eraseCurrentResults", {});
    },
  },
  beforeMount() {
    this.initialisation();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>